import React, { useReducer, createContext, useEffect } from "react";
import useLocalStorage from "./useLocalStorage";
import md5 from 'md5-hash'


const initialState = {
  islogin:false,
  user:{},
  lang:"en"
};

export const StateContext = createContext();
export const DispatchContext = createContext();


const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOGIN":
        return {
          ...state,
          user: {
            ...action.payload.user,
            token:md5(action.payload.user.rol+"exped123"+action.payload.user.id+action.payload.user.session)
          }
    };

    case "TOGGLE_LOGIN_STATE":
        return {
          ...state,
          islogin: !state.islogin
    };
    
    case "TOGGLE_LOGOUT":
      return {
        ...state,
        user:{},
        islogin: false
    };
    case "CHANGE_LANG":
      return {
        ...state,
        lang:action.payload.lang
    };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};



export const loginUser=(dispatch,user) =>{
  return dispatch({
    type: "TOGGLE_LOGIN",
    payload: {
      user: user
    }
  }),dispatch({type: "TOGGLE_LOGIN_STATE"});
}


export const updateUserContext=(dispatch,user) =>{
  return dispatch({
    type: "TOGGLE_LOGIN",
    payload: {
      user: {
        ...user
      }
    }
  });
}




export const logout=(dispatch) =>{
  return dispatch({
    type: "TOGGLE_LOGOUT"
  });
}


export const changeLang=(dispatch,lang) =>{
  return dispatch({
    type: "CHANGE_LANG",
    payload: {
      lang: lang
    }
  });
}


const PrivatelocProvider = ({ children }) => {
  const [userdata, setuserdata] = useLocalStorage("user",{});
  const [islogin, setislogin] = useLocalStorage("islogin",false);
  const [lang, setLang] = useLocalStorage("lang","en");
  const info = {
    islogin:islogin,
    user: {
      ...userdata
    },
    lang:lang
  };

  const [state, dispatch] = useReducer(reducer, info);

  useEffect(() => {
    setuserdata(state.user);
    setislogin(state.islogin);
    setLang(state.lang)
  }, [state.user,state.islogin,state.lang]);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default PrivatelocProvider;