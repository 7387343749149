import React from 'react'
import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap'
import {FiTruck} from "react-icons/fi"
import {GoHome} from "react-icons/go"
import {BsCalendarCheck} from "react-icons/bs"
import {MdComputer} from "react-icons/md"

const DivC=styled.div`

padding-top:80px;
padding-bottom:80px;
background-color: #FDFDFD;
img{
    height:auto;
    max-width:100%
}
`
function Section3() {
  return (
    <DivC>
        <Container>
            <Row>
                
                <Col md={6} sm={12} className=" d-flex align-items-center justify-content-center  flex-column p-3">
          
                    <h1 className="h1title">Nous vous donnons le Contrôle total de Votre envoi.</h1>
                    <p>Aliquam tincidunt sapien et nisi feugiat, nec lacinia ante fringilla. Nullam vestibulum venenatis ante et porttiteory Fusce sit amet bib endum erat. Cras commodo nibh dui Integer eget tincid magna. Aliquam gravida, auglaoreet pulvinar, est lacus fringilla non<br/>
                    </p>
                    <p>
                        cursus risus lacus nec lacus. Nam elementum odio risus id bibendum neque sagittis ut. Integer a ipsum nibhonec sed dui arcu.</p>
                </Col>
                <Col md={6} sm={12} className="text-center d-flex align-items-center  flex-column">
                    <img src="https://exped.wegobox.fr/static/media/about-company.70d2082919a6f85b77c9.png" alt="" />
                </Col>
            </Row>
        </Container>

    </DivC>
  )
}






export default Section3