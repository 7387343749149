



import logov from "./logov.png"
import logow from "./logow.png"
import phone from "./phone.png"
import img_r from "./img-r.png"
import appstore from "./appstore.svg"
import googleplay from "./googleplay.svg"

// import car from "./car.png"
const images={
    logov,
    img_r,
    appstore,googleplay,logow,phone
}

export default images;