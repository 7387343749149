



import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import styled from "styled-components"
import images from '../images';
import IconButton from '@mui/material/IconButton';
import { GrClose, GrMenu } from "react-icons/gr"
import { MdSearch } from 'react-icons/md';
const DivC = styled.div`
height: 80px;
.navbar{
  height:80px!important;
  /* margin-top:40px; */
  
  min-height:80px!important;
  transition:padding .3s;
  display:flex;
  justify-content: center;
  background: #000; 
}
.navbar-inner{
  
  /* margin-left:50%; */
  /* transform:translateX(-50%); */
  /* max-width: 1300px; */
  width: 100%;

}
@media screen and (min-width: 992px){
  .navbar-inner{
    max-width: 960px;
  }
}
@media screen and (min-width: 1200px){
  .navbar-inner{
    max-width: 1140px;
  }
}
@media screen and (min-width: 1400px){
  .navbar-inner{
    max-width: 1320px;
  }
}
/* @media screen and (min-width: 768px){
  .navbar-inner{
    max-width: 720px;
  }
} */




.fix-navbar{
  margin-top:0px;
  position: fixed!important;
  top:0;
  left:0%;
  right:0;
  background:#FCFCFC;
  z-index: 100;
  box-shadow: 0px 0px 8px 0px #3333;
}

@media screen and (max-width: 1070px){
  .navbar{padding-left: 40px;
  padding-right:40px;
  margin-top:0px;}
}
@media screen and (max-width: 618px){
  .navbar{padding-left: 12px;
  padding-right:12px;}
}
.logo{
  height:34px;
  max-height:34px;
  /* max-width: 164px; */
  color:#FFF;
  font-size:24px
}
.logo img{
  height:100%;
  width:auto;
}


a{
  text-decoration: none;
  color: #EEE;
  /* background-color: #AAA; */
  padding:6px 12px;
  position:relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  border-radius:10px
}
a:hover{
  /* color:var(--main_color); */
  color:#FFF;

}

.exit-menu{
  display:none;
}
.btn-menu{
  color:#FFF!important;
  display:none;
}
.btn-menu path{
  stroke:#FFF!important;
}
@media screen and (min-width: 948px){
  .list-nav{display:flex!important}
}
@media screen and (max-width: 948px){
  
  .list-nav{
    position: fixed;
    flex-direction: column!important;
    top:0px;
    right:0px;
    bottom:0;
    
    width: 90%;
    max-width:400px;
    min-height:100%;
    background-color: #FFF;
    
    box-shadow:0px 0px 6px 0px #0002;
    display:none;
    z-index: 9999;
    animation:dm .5s;
  
  }
  .an-img{
    display:initial;
  }
  .list-nav a{
    display:block;
    padding:16px 22px;
    border-bottom:1px solid #AAA3;
    color:#333
  }
  .list-nav a:hover{
    color:#0071f2
  }
  .exit-menu{
    display:flex
  }
  .btn-menu{
    display:flex;
  }
  .logo{
    height: 28px;
  }
}
@keyframes dm{
  from{
    transform:translateX(120%);
  }
  to{
    transform:translateX(0%);
  }
}
.db-flex{
  display:flex;
}
.an-img{
    position: absolute;
    top:0;
    width:100%;
    left:0;
    transform: rotate(180deg);

}
.eiYghf .logo{
  display:inline!important
}
.btn-search{
  box-shadow: none;
  border:none;
  font-size: 14px;
  font-weight: 700;
  color:#FFF;
  display: none;
  background-color: #34df7d;
  border-radius:16px;
  min-width:100px;
  text-align:center
}
.btn-search svg{
  font-size: 18px;
}
@media screen and (max-width: 510px){
   .logo{
    height:auto
   }
   .logo-link{
    flex-grow:1;
   }
   .logo img{
    width: auto; 
     max-width: 100%;
   }
  
}
`
const Navbar = (props) => {
  const [open, setOpen] = useState(false);


  const [fixed, setFixed] = useState(false);

  const stickNavbar = () => {

    setFixed(window.scrollY > 200)
  }

 


  return <DivC >
    <div className={`navbar  ${fixed ? "fix-navbar" : ""}`}>
      <div className="d-flex align-items-center navbar-inner">
      <Link to={"/"} className="logo-link">
        <div className="logo me-4 d-flex align-items-center">
          <img src={images.logov} alt="" className="me-1" />
          
        </div>
      </Link>
      <div className={`d-flex justify-content-center flex-grow-1`} >

      {!props.fixedSearch&&<button className='btn btn-sm  btn-search justify-content-center' onClick={()=>props.btnClick()}>
        Devis

        
      </button>}

      </div>
      <div className={`list-nav  align-items-center justify-content-end ps-4 pe-4 `} style={{ display: open ? "block" : "none" }}>
        
        
        
        <div className="exit-menu  justify-content-end p-3 mb-3">

          <IconButton size="large" onClick={() => setOpen(false)} sx={{position:"relative"}}>
            <GrClose sx={{color:"#FFF!important"}} />
          </IconButton>
          
        </div>
       
        <a className="me-3" href="#login">Connexion</a>
        <a className="" href="#signup">Inscription</a>
        
      </div>

      <div className=" flex-grow-1 justify-content-end btn-menu">
        <IconButton color="white" size="large"  onClick={() => setOpen(true)} sx={{color:"#FFF"}}>
          <GrMenu sx={{color:"#FFF"}} />
        </IconButton>
      </div>
      </div>
    </div>

  </DivC>

}


export default Navbar