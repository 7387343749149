
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import lang from './lang';
import { useJsApiLoader, useLoadScript } from '@react-google-maps/api';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivatelocProvider from "./Components/Context/"
import images from "./Components/images/"
import Privateloc from './Components/Privateloc';
import ApiConfig from './Components/Config/ApiConfig';
import { createTheme,ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
   
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
    white: {
      // This is green.A700 as hex.
      main: '#FFF',
    },
  },
});

function App() {
  const [locale, setLocale] = useState('fr');
  const libraries = ["places"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: ApiConfig.googleMapsApiKey
  })

  return (isLoaded ? <PrivatelocProvider>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={lang[locale]}>

          <Router>
            <Routes>
              <Route path="/" element={<Privateloc />} />

            </Routes>
          </Router>

        </IntlProvider>
      </ThemeProvider>
    </PrivatelocProvider>
    : <><div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}><img src={images.loader} alt="" /> </div></>)
    ;
}

export default React.memo(App);

