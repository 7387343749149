import React from 'react'
import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap'
import {GoHome} from "react-icons/go"
import {BiMessageSquareDetail} from "react-icons/bi"
import {BsSpeedometer2} from "react-icons/bs"
import {AiOutlineControl} from "react-icons/ai"
import {MdSecurity}from "react-icons/md"
const DivC=styled.div`

padding-top:80px;
padding-bottom:80px;
h3{
    font-size:18px;
    font-weight: 700;
}
.icon{
    width: 80px;
    height:80px;
    border:3px solid #34df7d;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:20px;
    font-size: 40px;
    color:#34df7d
}
`
function Section1() {
  return (
    <DivC>
        <Container>
            <Row >
                <Col>
                    <h1 className="h1title text-center">Pourquoi nous Choisir!.</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={4} sm={12} className="text-center d-flex align-items-center  flex-column">
                    <div className='icon'><BsSpeedometer2/></div>
                    <h3>Vitesse de charge</h3>
                    <p>Wegobox C'est un service de livraison rapide en Algérie. Vos envois sont livrés en un temps record !</p>
                </Col>
                <Col lg={4} md={4} sm={12} className="text-center d-flex align-items-center  flex-column">
                    <div className='icon'><MdSecurity/></div>
                    <h3>Garantie</h3>
                    <p>Avec Wegobox, vos envois sont garantis et sécurisés de la réception, du transit à la livraison.</p>
                </Col>
                <Col lg={4} md={4} sm={12} className="text-center d-flex align-items-center  flex-column">
                    <div className='icon'><AiOutlineControl/></div>
                    <h3>Suivez vos envois</h3>
                    <p>
Vous pouvez suivre toutes les étapes de livraison de votre colis en temps réel et à tout moment sur la plateforme.</p>
                </Col>
            </Row>
        </Container>

    </DivC>
  )
}






export default Section1