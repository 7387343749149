import { useState } from 'react'
import styled from "styled-components"
import DtPicker from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/index.css'

// import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from 'react-switch';
import Select from 'react-select';
import wilayas from './AlgerW'
const DivC = styled.div`




/* margin-bottom:10px; */
width:100%;


.inner{
    background-color:#FFF;
    padding: 14px 18px;
border-radius:5px;
box-shadow: 0px 0px 5px 2px #0002;
}



input{
    border:none;
    outline: none;
    border-bottom: 1px solid #0002!important;
    border-radius:0;
}
p{
    font-weight: 600;
    margin-bottom:10px;
    font-size: 16px;
}
.div{
    /* border-left: 1px solid #0003; */
}
@media screen and (max-width: 785px){
.inner{flex-direction:column ;
width: 100%;}
.div{
    border-left: none;
    margin-top:20px
}
}
.type .btn{
    border:none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 800;
    border-radius:0;
    padding: 0;
    padding-top: 6px;
    border:3px solid transparent;
    color:#EEE
}
.type .btn.active{
    color: #FFF;
    border-bottom:3px solid #34df7d
}
.react-switch-handle{
    border:5px solid transparent!important;
    background-clip: border-box;
}
.react-calendar-datetime-picker .calender-modal{
    bottom:initial!important
}
.tspan{
    color: #fff;
    font-weight: 600;
}
.btnd{
    background-color: #34df7d;
    border-radius:20px;
    border:none;
    min-width: 100px;
    box-shadow: none!important;
    font-size:16px;
    font-weight:700
}
`
const wilaya=[
    "adrar",
    "chlef"
]
function Search() {
    const [wilayaDep,setWilayaDep ] = useState(null);
    const [wilayaArr,setWilayaArr]=useState(null);
    const [type, setType] = useState(true);
    const [deff_address, setDeff_address] = useState(false);
    return (
        <DivC className='d-flex flex-column div-search'>
            <div className="type mb-3">
                <button className={`btn me-2 ${type ? "active" : ""}`} onClick={() => setType(true)}>VOITURE</button>
                <button className={`btn ${type ? "" : "active"}`} onClick={() => setType(false)}>UTILITAIRE</button>
            </div>
            <div className='d-flex inner'>
                <div className='d-flex flex-column  flex-grow-1'>
                    <p className='m-0'>Wilaya de départ</p>
                    <Select
                        value={wilayaDep}
                        defaultValue={wilayaDep}
                        onChange={setWilayaDep}
                        placeholder="Choisir"
                        closeMenuOnSelect={true}
                        options={wilayas.map((el,key) => {
                            return { label: el.name +" - "+el.code, value: el.name +" - "+el.code,wilaya:el}
                        })}
                    />
                </div>
                <div className='me-1 ms-1 div'></div>
                <div className='d-flex flex-column  flex-grow-1'>
                    <p className='m-0'>Wilaya d’arrivée</p>
                    <Select
                        value={wilayaArr}
                        defaultValue={wilayaArr}
                        menuPosition="left"
                        
                        onChange={setWilayaArr}
                        closeMenuOnSelect={true}
                        placeholder="Choisir"
                        options={wilayas.map((el,key) => {
                            return { label: el.name +" - "+el.code, value: el.name +" - "+el.code,wilaya:el}
                        })}
                    />
                </div>
                <div className='me-3 ms-3 div'></div>
                <div className='d-flex flex-column justify-content-center'>
                    <button className="btn btn-primary btnd">Devis</button>
                </div>
            </div>
            <div className="type mt-2 d-flex align-items-center">
                {/* <FormControlLabel
                    value="end"
                    control={<Switch color="primary" />}
                    label="Rendre à une adresse différente"
                    labelPlacement="end"
                    onChange={() => setDeff_address(!deff_address)}
                /> */}
                

                    <Switch
                        checked={deff_address}
                        onChange={() => setDeff_address(!deff_address)}
                        onColor="#34df7d"
                        offColor="#EEE"
                        onHandleColor="#fff"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 3px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                    />
                    <span className="ms-2 text-light tspan">Livraison à domicile ?</span>
                
            </div>
        </DivC>
    )
}

export default Search