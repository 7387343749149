
const url="https://apidash.codany.net/";
// const url="http://localhost:80/react_exped/";
const ApiConfig = {
    url:url,
    images:url+"public/up/",
    apikey:'F8E74BF7B9A6137EC28454C893287',
    googleMapsApiKey: "AIzaSyC6x3KJb3vxdGQwUMRWkgvCq4rEDNFtMsU",
    content_type:'Content-type',
    img_url:'',
    id_partnair:"1",
    firebase:false,
  
};
export default ApiConfig;