export default {
    
    fr: {
        DIR:"ltr",


        DASHBOARD:"Dashboard",
        ENTREPOT:"Entrepôts",
        GES_COLIS:"Gestion des colis",
        USERS:"Utilisateurs",
        NOTIFICATIONS:"Notifications",
        CALCULATOR:"Calculateur",
        PROFILE:"Profile",

        COLI_EN_ROUT:"Colis en route",
        COLIS_ARR:"Colis arrivés",
        COLIS_PREP:"En cours de préparation",
        COLIS_ATT_PAIE:"En attente de paiement",
        COLIS_ATT_VALID:"En attente de validation",
        COLIS_PAIE_ACC:"Paiement accépté",
        COLIS_REJ:"Paiement rejeté",
        COLIS_DEC_REJ:"Declaration rejet",
        COLIS_ENV:"Colis envoyés",
        COLIS_REC:"Colis reçus",
        COLIS_LIST:"Liste des colis",
        RECHARGE_DONE:"Recharge effectuée",
        ATT_PAIE:"En attente de paiement",
        ATT_VALID:"En attente de validation",
        PAIE_ACC:"Paiement accépté",
        PAIE_REJ:"Paiement rejeté",

        STORES:"Entrepôts",
        STORE:"Entrepôt",
        STORE_LIST:"Liste des entrepôts",
        STORE_NAME:"Nom de l'entrepôt",
        ID:"Id",
        ID_EXPED:"Id exped",
        TRACK_NUMBER:"Numéro de suivi",
        WEIGHT:"Poids",
        LENGTH: "Longueur",
        WIDTH: "Largeur", 
        HEIGHT:"Hauteur",
        VOL_WEIGHT:"Poids Vol",
        KG:"Kg",
        CM:"cm",
        IMAGES:"Photos",
        STATE:"Etat",
        NO_IMAGES:"Aucune image",
        ACTION:"Action",
        IMAGES5:"5 photos maximum",
        COUNTRY:"Pays",
        STATEC:"Région ",
        CITY:"Ville",
        ZIP_CODE:"Code postal",
        ADDRESS_LINE:"Adresse Ligne",
        COMPANY:"Société",
        LOCATION:"Localisation",



        SUBMIT:"Soumettre",
        SAVE:"Sauvegarder",
        ADD:"Ajouter",
        BACK:"Retour",
        SEND:"Envoyer",
        PAY:"Payer",
        YES:"Oui",
        NO:"No",
        TOOLS:"Outils",

        USERS_LIST:"Liste des utilisateurs",
        FNAME:"Prénom",
        NAME:"Nom",
        LNAME:"Nom",
        BIRTH_DATE:"Date de naissance",
        GENDER:"Genre",
        SELECT_GENDER:"Sélectionnez le Genre",
        EMAIL:"E-mail",
        PHONE:"N° phone",
        ROL:"Rôle",
        EDIT_DATE:"Date de modification",
        ADD_DATE:"Date ajoutée",
        RESBONSABLE_FOR:"Responsable de",
        MALE:"Mâle",
        FEMALE:"Femelle",
        OLD_PASS:"Ancien mot de passe",
        NEW_PASS:"Nouveau mot de passe",
        CNEW_PASS:"Confirmer nouveau mot de passe",
        PASSWORD:"Mot de pass",
        CPASSWORD:"Confirmez le mot de passe",
        CALC_TITLE:"Calculateur de frais d'envoi",
        CALCULATE:"Calculer",
        FROM:"De",
        TO:"à",
        NOTES:"Remarques",
        TAG_ADDRESS:"Tag de l’adresse",

        YOUR_ADDRESS_EXPED:"Votre Adress Exped",
        YOUR_DELEVERY_ADDRESS:"Vos adresse de livraison",
        PERSONEL_INFO:"Informations personnelles",
        CHANGE_PASS:"Changer le mot de passe",
        SOCIAL_MEDIA:"Médias sociaux",
        SHOW:"Afficher",
        CLOSE:"Fermer",
        MESSAGE_ALL_ADD:"Tous les colis doivent porter votre ID",
        MESSAGE_ALL_ADD2:"sinon nous ne pouvons pas savoir à qui ils appartiennent. Veuillez informer la personne qui vous expédie le colis.",

        LOGIN:"Connexion",
        WELCOM_EXPED:"Bienvenue chez Exped, La filiale de WegoBox !",
        CREATE_YOUR_ACC:"Créez votre Compte Sur Exped!",
        REMMEMBER_ME:"Ce souvenir de moi",
        NEW_USER_EXPED:"Nouveau sur notre plateforme ?",
        CREATE_ACCOUNT:" Créer un compte",
        PRIVACY_POL:"Politique de confidentialité",
        ALREADY_REG:"Vous avez déjà un compte ?",
        TO_LOGIN:" Se connecter",
        REGISTER:"S'inscrire",
        CHANGE_STATE:"Changer de statut",
        CHANGE:"Changer",
        CONFIRM_SEND_COLIS:"Etes-vous sûre de bien vouloir envoyer ce colis?",
        CONFIRM_SEND_COLIS2:"Cette étape est définitive",
        CONFIRMATION:"Confirmation",
        REQUEST_PAY:"Demande de paiement",


        TAB_NOMATCH:"Désolé, aucun enregistrement correspondant trouvé",
        TAB_TOOLTIP:"Sorte",
        TAB_COL_TOOLTIP:"Trier pour",
        TAB_NEXT:"Page suivante",
        TAB_PREV:"Page précédente",
        TAB_ROWSPERPAGE:"Lignes par page:",
        TAB_DISPLAY_ROW:"De",

        TAB_SEARCH:"Recherche",
        TAB_DOWNCSV:"Télécharger CSV ",
        TAB_PRINT:"Imprimer",
        TAB_VCOL:"Afficher les colonnes",
        TAB_FILTER:"Tableau de filtrage",

        TAB_ALL:"TOUTE",
        TAB_FILTITLE:"FILTRES",
        TAB_RESET:"RÉINITIALISER",
        TAB_FILTYPE:"menu déroulant",
        TAB_RESPONSIVE:"empilée",
        TAB_VIEWTITLE:"Afficher les colonnes",
        TAB_VIEWAREA:"Afficher/Masquer les colonnes du tableau",
        TAB_SELROWTEXT:"ligne(s) sélectionnée(s)",
        

        NOTES_TITLE:"Articles interdits",
        NOTES_SUBTITLE:"Articles Interdits et Limités",

        BANK_MANAG:"Gestion bancaire",
        SHIPPING_REQ:"Exigences d'expédition",

        PAIM_VAL:"Paiement validé",
        DEME_EFECT:"Demande effectuée",
        CARTE_ROUT:"Carte en route", 
        CARTE_ARR:"Carte arrivée"
    },
    en:{
       
        
    
    }
  };